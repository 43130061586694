import { HamburgerItem } from '../hamburger-item/hamburger-item';

import './hamburger.scss';

const Hamburger: React.FunctionComponent<{open: boolean, onClickCallback: Function}> = (props) => {
  return (
    <div className={`hamburger${props.open ? ' open' : ''}`} onClick={() => props.onClickCallback()}>
      <div className='hamburger__content'>
        <p className='hamburger__title'>MENU</p>
        <img className='hamburger__close' src='./images/components/hamburger/close.png' alt='close' onClick={() => props.onClickCallback()} />
        <div className='hamburger__items'>
          <HamburgerItem jaText='トップ' enText='TOP' link='/' />
          <HamburgerItem jaText='事業内容' enText='OUR BUSINESS' link='/business' />
          <HamburgerItem jaText='会社概要' enText='OUR COMPANY' link='/company' />
          <HamburgerItem jaText='お問い合わせ' enText='CONTACT' link='/#contact-sp' />
          <HamburgerItem jaText='会社所在' enText='ACCESS' link='/#access-sp' />
        </div>
      </div>
    </div>
  )
}

export { Hamburger };