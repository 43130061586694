import { Title } from "../../../../components/title/title";

import './contact.scss';

const Contact: React.FunctionComponent = () => {
  return (
    <div className='contact'>
      <div className='contact__title'>
        <Title jaText="お問い合わせ" enText="CONTACT" />
      </div>

      <div className="contact__content">
        <div className="contact__content__overlay">
          <p className="contact__text">お電話でのお問い合わせはこちら</p>
          <a className="contact__button" href="tel:058-213-8865">058-213-8865</a>

          <p className="contact__text">メールでのお問い合わせはこちら</p>
          <a className="contact__button" href="mailto:ankline001@outlook.jp">ankline001@outlook.jp</a>
        </div>
      </div>
    </div>
  )
}

export { Contact };