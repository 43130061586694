import './card.scss';

const Card: React.FunctionComponent<{ title: string, src: string, text1: string, text2: string }> = (props) => {
  return (
    <div className='card'>
      <div className='card__title'>{props.title}</div>
      <img className='card__image' src={props.src} alt={props.title} />
      <div className='card__text'>{props.text1}</div>
      <div className='card__text'>{props.text2}</div>
    </div>
  )
}

export { Card };