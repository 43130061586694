import { HeaderSp } from '../../components/header-sp/header-sp';
import { HeaderPc } from '../../components/header-pc/header-pc';
import { FooterSp } from '../../components/footer-sp/footer-sp';
import { FooterPc } from '../../components/footer-pc/footer-pc';
import { Overview } from './components/overview/overview';
import { History } from './components/history/history';
import { Hub } from './components/hub/hub';
import { Resource } from './components/resource/resource';

import { Helmet } from "react-helmet-async"
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Company: React.FunctionComponent = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation()
  
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [pathname])
  
    return null
  }
  
  return (
    <div className='wrapper'>
      <Helmet>
        <title>アンクライン株式会社 ｜ 運送、物流加工、輸入販売 ｜ 会社概要</title>
      </Helmet>

      <ScrollToTop />

      <div className='pc'>
        <HeaderPc />
        <Overview />
        <History />
        <Hub />
        <Resource />
        <FooterPc />
      </div>

      <div className='sp'>
        <HeaderSp />
        <Overview />
        <History />
        <Hub />
        <Resource />
        <FooterSp />
      </div>
    </div>
  )
}

export { Company };