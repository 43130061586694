import './footer-sp.scss';

const FooterSp: React.FunctionComponent = () => {
  return (
    <div className="footer">
      <div className='footer__inner'>
        <div className="footer__name">
          <img className="footer__name--icon" src="./images/components/footer/logo.png" alt="ANKLINE INC."/>
          <span className="footer__name--en">ANKLINE INC.</span>
          <span className="footer__name--ja">アンクライン株式会社</span>
        </div>

        <div className="footer__address">
          <span className="footer__address__text">〒501-6004</span>
          <span className="footer__address__text">岐阜県羽島郡岐南町野中2-9</span>
        </div>

        <div className="footer__tel">
          <a className="footer__tel__text" href="tel:058-213-8865">TEL： 058-213-8865</a>
          <a className="footer__tel__text" href="tel:058-213-8864">FAX： 058-213-8864</a>
        </div>

        <div className="footer__mail">
          <a className="footer__mail__text" href="mailto:ankline001@outlook.jp">MAIL： ankline001@outlook.jp</a>
        </div>
        <div className="footer__copyright">Copyright (C) アンクライン株式会社 All Rights Reserved.</div>
      </div>
    </div>
  )
}

export { FooterSp };