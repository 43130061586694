import { Link } from "react-router-dom";

import './business.scss';

const Business: React.FunctionComponent = () => {
  return (
    <div className='business'>
      <img className='business__image' src='./images/features/top/components/business/solve.png' alt='運ぶだけじゃない 物流に関わるあらゆる問題を解決' />
      <div className='business__content'>
        <span className='business__text'>アンクライン株式会社は、岐阜県西濃地区を拠点とした総合物流企業です。</span>
        <span className='business__text'>私たちは単に物を運ぶだけでなく、物が動く時に生まれるお客様の様々な悩みを解決します。</span>
        <span className='business__text'>受け取る・保管する・運ぶことはもちろん、物を直接販売することも得意。</span>
        <span className='business__text'>だから、弊社だけでお客様の悩みの多くに一括でご相談にのることができます。</span>
        <Link to='/business' className='business__button'>OUR BUSINESS</Link>
      </div>
    </div>
  )
}

export { Business };