import { useState } from "react";
import { Hamburger } from './../hamburger/hamburger';

import './header-sp.scss';

const HeaderSp: React.FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const toggleHamburger = () => {
    setOpen(!open);
  };

  return (
    <div className="header">
      <div className="header__logo">
        <img className="header__icon" src="./images/components/header/logo.png" alt="ANKLINE INC."/>
        <p className="header__main-text">ANKLINE INC.</p>
        <p className="header__sub-text">
          <span className="header__sub-text--name">アンクライン株式会社</span>
          <span className="header__sub-text--caption">運送・物流加工・輸入販売</span>
        </p>
      </div>

      <div className='header__hamburger' onClick={toggleHamburger}>
        <span className='header__hamburger__line'></span>
        <span className='header__hamburger__line'></span>
        <span className='header__hamburger__line'></span>
      </div>

      <Hamburger open={open}
                 onClickCallback={() => toggleHamburger()} />
    </div>
  )
}

export { HeaderSp };