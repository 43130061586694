import { HashLink } from 'react-router-hash-link';

import './hamburger-item.scss';

const HamburgerItem: React.FunctionComponent<{jaText: string, enText: string, link: string}> = (props) => {
  const scrollWithOffset = (el: Element) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -52; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
  }

  return (
    <HashLink to={props.link} className='hamburger-item' scroll={el => scrollWithOffset(el)}>
      <span className='hamburger-item__text hamburger-item__text--ja'>{props.jaText}</span>
      <span className='hamburger-item__text'> / </span>
      <span className='hamburger-item__text hamburger-item__text--en'>{props.enText}</span>
    </HashLink>
  )
}

export { HamburgerItem };