import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Top } from "./features/top/index";
import { Business } from "./features/business/index";
import { Company } from "./features/company/index";

import './App.scss';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Top/>} />
        <Route path="/company" element={<Company/>} />
        <Route path="/business" element={<Business/>} />
      </Routes>
    </Router>
  );
}

export default App;