import { Title } from "../../../../components/title/title";

import './overview.scss';

const Overview: React.FunctionComponent = () => {
  return (
    <div className='overview'>
      <div className='overview__title'>
        <Title jaText="会社概要" enText="OUR COMPANY" />
      </div>

      <div className='overview__line'>
        <span className='overview__line__header'>名　称</span>
        <span className='overview__line__text'>アンクライン株式会社</span>
      </div>

      <div className='overview__line'>
        <span className='overview__line__header'>設　立</span>
        <span className='overview__line__text'>令和３年８月６日</span>
      </div>

      <div className='overview__line'>
        <span className='overview__line__header'>資本金</span>
        <span className='overview__line__text'>３００万円</span>
      </div>

      <div className='overview__line'>
        <span className='overview__line__header'>所在地</span>
        <span className='overview__line__text'>
          〒500-8258<br />
          岐阜県岐阜市西川手5丁目110番地1
        </span>
      </div>

      <div className='overview__line'>
        <span className='overview__line__header'>TEL</span>
        <span className='overview__line__text'>058-216-4664</span>
      </div>

      <div className='overview__line'>
        <span className='overview__line__header'>代表者</span>
        <span className='overview__line__text'>安田 博之</span>
      </div>

      <div className='overview__line'>
        <span className='overview__line__header'>従業員</span>
        <span className='overview__line__text'>20名（内パート従業員6名）</span>
      </div>

      <div className='overview__line'>
        <span className='overview__line__header'>営業内容</span>
        <span className='overview__line__text'>
          アパレル全般の物流加工・梱包・出荷・ラッピング等<br />
          一般貨物自動車運送事業<br />
          自動車運送取扱事業<br />
          EC事業・輸入販売事業
        </span>
      </div>

      <div className='overview__line'>
        <span className='overview__line__header'>取引銀行</span>
        <div className='overview__line__text overview__line__text--double'>
          <div>
            ・大垣共立銀行<br />
            ・岐阜信用金庫<br />
            ・岐阜商工信用組合
          </div>
          <div>
            ・岐南支店<br />
            ・城東支店<br />
            ・本店営業部
          </div>
        </div>
      </div>

      <div className='overview__line'>
        <span className='overview__line__header'>主要取引先</span>
        <span className='overview__line__text overview__line__text--double'>
          <div>
            ・愛知県経済農業協同組合連合会<br />
            ・エレネ株式会社<br />
            ・株式会社アクティブ二一<br />
            ・株式会社セントラル・ブレイン<br />
            ・西濃運輸株式会社　岐阜支店<br />
            ・有限会社パーク<br />
            ・株式会社ひまわりカーゴサービス<br />
            ・株式会社ファミリー・ライフ<br />
          </div>
          <div>
            ・佐川急便株式会社　岐阜営業所<br />
            ・株式会社毛利米穀<br />
            ・株式会社グラト<br />
            ・株式会社KnK<br />
            ・丸徳産業株式会社<br />
            ・株式会社IKホールディングス<br />
            ・株式会社ロマン<br />
            ・株式会社プライムダイレクト<br />
            （順不同）
          </div>
        </span>
      </div>
    </div>
  )
}

export { Overview };