import { Title } from "../../../../components/title/title";

import './history.scss';

const History: React.FunctionComponent = () => {
  return (
    <div className='history'>
      <div className='history__title'>
        <Title jaText="会社経歴" enText="OUR HISTORY" />
      </div>

      <div className="history__content">
        <p className="history__line">
          <span className="history__line__header">令和3年8月</span>
          <span>岐阜市にてアンクライン株式会社として事業開始</span>
        </p>

        <p className="history__line">
          <span className="history__line__header">令和3年11月</span>
          <span>本巣市に営業所新設</span>
        </p>

        <p className="history__line">
          <span className="history__line__header">令和3年11月</span>
          <span>岐南町平成5-103に事業所新設</span>
        </p>

        <p className="history__line">
          <span className="history__line__header">令和3年12月</span>
          <span>岐南町平成5-106に事業所新設</span>
        </p>

        <p className="history__line">
          <span className="history__line__header">令和4年7月</span>
          <span>羽島郡に営業所新設</span>
        </p>

        <p className="history__line">
          <span className="history__line__header">令和4年11月</span>
          <span>平成事業所にてEC事業開始</span>
        </p>

        <p className="history__line">
          <span className="history__line__header">令和4年12月</span>
          <span>岐南町平成事業所を5-106へ集約統合</span>
        </p>

        <p className="history__line">
          <span className="history__line__header">令和5年9月</span>
          <span>本巣営業所にて一般貨物自動車運送事業許可取得</span>
        </p>
      </div>
    </div>
  )
}

export { History };