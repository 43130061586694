import { Title } from "../../../../components/title/title";

import './access.scss';

const Access: React.FunctionComponent = () => {
  return (
    <div className='access'>
      <div className='access__title'>
        <Title jaText="本社営業所" enText="ACCESS" />
      </div>

      <p className="access__text">〒501-6004　岐阜県羽島郡岐南町野中2-9</p>

      <div className="access__map">
        <iframe 
          title="〒501-6004　岐阜県羽島郡岐南町野中2-9"
          className="access__map__content"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3252.7103112593154!2d136.79528547568825!3d35.38764024604616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6003a86fa42532b1%3A0xd8d360a1b99b1c1b!2z44CSNTAxLTYwMDQg5bKQ6Zic55yM57695bO26YOh5bKQ5Y2X55S66YeO5Lit77yS5LiB55uu77yZ!5e0!3m2!1sja!2sjp!4v1721192423930!5m2!1sja!2sjp"
          width="100%"
          height="100%"
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade" />
      </div>
    </div>
  )
}

export { Access };