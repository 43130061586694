import { HeaderSp } from '../../components/header-sp/header-sp';
import { HeaderPc } from '../../components/header-pc/header-pc';
import { FooterSp } from '../../components/footer-sp/footer-sp';
import { FooterPc } from '../../components/footer-pc/footer-pc';
import { Video } from './components/video/video';
import { Business } from './components/business/business';
import { CompanySp } from './components/company-sp/company-sp';
import { CompanyPc } from './components/company-pc/company-pc';
import { Recruit } from './components/recruit/recruit';
import { Contact } from './components/contact/contact';
import { Access } from './components/access/access';

import { Helmet } from "react-helmet-async"

import './index.scss';

const Top: React.FunctionComponent = () => {
  return (
    <div className='wrapper'>
      <Helmet>
        <title>アンクライン株式会社 ｜ 運送、物流加工、輸入販売</title>
      </Helmet>

      <div className='pc'>
        <HeaderPc />
        <Video />
        <Business />

        <div className='wrapper__columns'>
          <div className='wrapper__column'><CompanyPc /></div>
          <div className='wrapper__column'><Recruit /></div>
        </div>

        <div id='contact-pc'><Contact /></div>
        <div id='access-pc'><Access /></div>
        <FooterPc />
      </div>

      <div className='sp'>
        <HeaderSp />
        <Video />
        <Business />
        <CompanySp />
        <Recruit />
        <div id='contact-sp'><Contact /></div>
        <div id='access-sp'><Access /></div>
        <FooterSp />
      </div>
    </div>
  )
}

export { Top };