import { HeaderSp } from '../../components/header-sp/header-sp';
import { HeaderPc } from '../../components/header-pc/header-pc';
import { FooterSp } from '../../components/footer-sp/footer-sp';
import { FooterPc } from '../../components/footer-pc/footer-pc';
import { Title } from './../../components/title/title';
import { Card } from './components/card/card';

import { Helmet } from "react-helmet-async"

import './index.scss';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Business: React.FunctionComponent = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation()
  
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [pathname])
  
    return null
  }
  
  return (
    <div className='wrapper'>
      <Helmet>
        <title>アンクライン株式会社 ｜ 運送、物流加工、輸入販売 ｜ 事業内容</title>
      </Helmet>

      <ScrollToTop />

      <div className='pc'><HeaderPc /></div>
      <div className='sp'><HeaderSp /></div>
      

      <div className='wrapper__title'>
        <Title jaText='事業内容' enText='OUR BUSINESS' />
      </div>
      <div className='wrapper__card'>
        <Card title='一般貨物自動車運送事業'
              src='./images/features/business/components/card/kamotsu.png'
              text1='一般貨物自動車運送事業は、国土交通省の許可を受けたトラックで有償の貨物輸送を行う事業です。'
              text2='企業や個人からの依頼に応じて荷物を集荷し、目的地まで安全かつ効率的に運びます。' />
        <Card title='自動車運送取扱事業'
              src='./images/features/business/components/card/toriatsukai.png'
              text1='自動車運送取扱事業は、貨物運送の手配や管理を行う事業です。'
              text2='この事業の主な役割は、荷主（貨物を送りたい人や企業）と運送業者（実際に貨物を運ぶトラック会社）をつなげることです。' />
        <Card title='物流加工・梱包・出荷・ラッピング'
              src='./images/features/business/components/card/konpo.png'
              text1='主にアパレル製品の物流加工・梱包・出荷・ラッピングを行っています。 製品の品質を維持するため、丁寧な梱包や効率的な出荷プロセスを提供。'
              text2='確かな品質管理とスピーディな配送で、顧客満足度を追求します。' />
        <Card title='EC事業・輸入販売事業'
              src='./images/features/business/components/card/ec.png'
              text1='物流知識を活かしたEC事業と輸入販売事業を主軸に展開しています。 物流の知見と優れたサービスで、お客様に価値ある商品を提供しています。'
              text2='独自の調達ネットワークと効率的な物流システムを活用し、迅速で確実な配送を実現。お客様のニーズに柔軟に対応し、市場での競争力を高めます。' />
        <div className='dummy'></div>
        <div className='dummy'></div>
      </div>

      <div className='pc'><FooterPc /></div>
      <div className='sp'><FooterSp /></div>
    </div>
  )
}

export { Business };