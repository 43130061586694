import './title.scss';

const Title: React.FunctionComponent<{ jaText: string, enText: string }> = (props) => {
  return (
    <div className='title'>
      <span className='title__text title__text--ja'>{props.jaText}</span>
      <span className='title__text'>　/　</span>
      <span className='title__text title__text--en'>{props.enText}</span>
      <span className='title__slash'></span>
      <span className='title__slash'></span>
      <span className='title__slash'></span>
    </div>
  )
}

export { Title };