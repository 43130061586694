import { Title } from "../../../../components/title/title";

import './hub.scss';

const Hub: React.FunctionComponent = () => {
  return (
    <div className='hub'>
      <div className='hub__title'>
        <Title jaText="各拠点" enText="OUR HUB" />
      </div>

      <div className="hub__content hub__content--house">
        《岐南営業所》梱包・物流加工事業<br />
        岐阜県羽島郡岐南町野中2-9<br />
        TEL: 058-213-8865
        <span className="hub__content__fax">FAX: 058-213-8864</span>
      </div>

      <div className="hub__content hub__content--truck">
        《本巣営業所》一般貨物自動車運送事業<br />
        岐阜県本巣市春近209-1<br />
        TEL: 058-260-8380 
      </div>

      <div className="hub__content hub__content--building">
        《平成事業所》ＥＣ事業・輸入販売卸売事業<br />
        岐阜県羽島郡岐南町平成5-106<br />
        TEL: 058-216-4310
      </div>
    </div>
  )
}

export { Hub };