import { Title } from "../../../../components/title/title";

import './resource.scss';

const Resource: React.FunctionComponent = () => {
  return (
    <div className='resource'>
      <div className='resource__title'>
        <Title jaText="保有車両" enText="OUR RESOURCE" />
      </div>

      <div className="resource__content resource__content--big">
        <span className="resource__content__text">大型車（13tウイング）</span>
        <span className="resource__content__text">中型車（4tウイング）</span>
      </div>

      <div className="resource__content resource__content--middle">
        <span className="resource__content__text">中型車（4tウイング格納ゲート）</span>
        <span className="resource__content__text">中型車（3tバン）</span>
      </div>

      <div className="resource__content resource__content--small">
        <span className="resource__content__text">小型車（1tバン）</span>
        <span className="resource__content__text">軽貨物</span>
      </div>

      <div className="resource__content resource__content--lift">
        <span className="resource__content__text">◇フォークリフト</span>
        <span className="resource__content__text"></span>
      </div>
    </div>
  )
}

export { Resource };