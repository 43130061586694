import { HashLink } from 'react-router-hash-link';

import './header-item.scss';

const HeaderItem: React.FunctionComponent<{ jaText: string, enText: string, link: string }> = (props) => {
  const scrollWithOffset = (el: Element) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -0.1 * window.innerHeight; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
  }

  return (
    <HashLink to={props.link} className="header-item__link" scroll={el => scrollWithOffset(el)}>
        <span className="header-item__ja">{props.jaText}</span>
        <span className="header-item__en">{props.enText}</span>
    </HashLink>
  )
}

export { HeaderItem };