import { HeaderItem } from '../header-item/header-item';

import './header-pc.scss';

const HeaderPc: React.FunctionComponent = () => {
  return (
    <div className="header">
      <div className="header__logo">
        <img className="header__icon" src="./images/components/header/logo.png" alt="ANKLINE INC."/>
        <p className="header__main-text">ANKLINE INC.</p>
        <p className="header__sub-text">
          <span className="header__sub-text--name">アンクライン株式会社</span>
          <span className="header__sub-text--caption">運送・物流加工・輸入販売</span>
        </p>
      </div>

      <div className='header__items'>
        <div className='header__item__wrapper'>
          <HeaderItem jaText="トップ" enText="TOP" link="/" />
        </div>
        <div className='header__item__wrapper'>
          <HeaderItem jaText="事業内容" enText="OUR BUSINESS" link="/business" />
        </div>
        <div className='header__item__wrapper'>
          <HeaderItem jaText="会社概要" enText="OUR COMPANY" link="/company" />
        </div>
        <div className='header__item__wrapper'>
          <HeaderItem jaText="お問い合わせ" enText="CONTACT" link="/#contact-pc" />
        </div>
        <div className='header__item__wrapper'>
          <HeaderItem jaText="会社所在" enText="ACCESS" link="/#access-pc" />
        </div>
      </div>
    </div>
  )
}

export { HeaderPc };