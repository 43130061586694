import { Title } from "../../../../components/title/title";

import { Link } from "react-router-dom";

import './company-sp.scss';

const CompanySp: React.FunctionComponent = () => {
  return (
    <div className='company'>
      <div className='company__title'>
        <Title jaText="会社概要" enText="COMPANY" />
      </div>

      <img className="company__image" src="./images/features/top/components/company-sp/company.png" alt="ANKLINE Inc."/>
      
      <div className="company__content">
        <p className="company__item">
          <span className="company__item__header">名　称</span>
          <span className="company__item__text">アンクライン株式会社</span>
        </p>
        <p className="company__item">
          <span className="company__item__header">業務内容</span>
          <span className="company__item__text">物流全般業務・販売全般業務</span>
        </p>
        <p className="company__item">
          <span className="company__item__header">設　立</span>
          <span className="company__item__text">令和３年８月６日</span>
        </p>
        <p className="company__item">
          <span className="company__item__header">資本金</span>
          <span className="company__item__text">３００万円</span>
        </p>
        <p className="company__item">
          <span className="company__item__header">TEL</span>
          <span className="company__item__text">058-213-8865</span>
        </p>
        <p className="company__item">
          <span className="company__item__header">代表者</span>
          <span className="company__item__text">安田 博之</span>
        </p>
        
        <Link to='/company' className='company__button'>OUR COMPANY</Link>
      </div>
    </div>
  )
}

export { CompanySp };