import './video.scss';

const Video: React.FunctionComponent = () => {
  return (
    <div className='video'>
      <video className='video__content' src="./videos/features/top/components/video/top.mp4" autoPlay muted playsInline loop></video>

      <div className='video__overlay'>
        <img className='video__thankyou' src='./images/features/top/components/video/thankyou.png' alt='ありがとう' />
        <span className='video__text video__text--main'>
          ”モノ”が”ながれる”とき、 同時に誰かの思いが誰かに 伝わる。 
        </span>
        <span className='video__text video__text--main'>
          物流のあらゆるパートを担う、 重要な担い手でありたい。
        </span>
        <span className='video__text video__text--name'>
          アンクライン株式会社
        </span>
      </div>
    </div>
  )
}

export { Video };