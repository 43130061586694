import { Title } from "../../../../components/title/title";

import './recruit.scss';

const Recruit: React.FunctionComponent = () => {
  return (
    <div className='recruit'>
      <Title jaText="採用情報" enText="RECRUIT" />
      <p className="recruit__catch">随時ドライバー募集中!</p>
      <p className="recruit__list">
        <span className="recruit__list__item">未経験者OK</span>
        <span className="recruit__list__item">中型運転免許保持者の方</span>
      </p>
      <div className="recruit__image"></div>
      <p className="recruit__notice">詳しい条件や待遇などお気軽にお問い合わせください</p>
    </div>
  )
}

export { Recruit };